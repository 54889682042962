<template>
  <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>等级列表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="search_box">
      <span>手机号：</span>
      <el-input
        style="width: 200px"
        v-model="formData.phone"
        placeholder="请输入手机号"
      />
      <el-button type="primary" class="btn" @click="getTableList()"
        >查询</el-button
      >
    </div>

    <el-table
      border
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column prop="trueName" label="姓名"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="money" label="充值金额"> </el-table-column>
      <el-table-column prop="addTime" label="充值时间"> </el-table-column>
      <el-table-column prop="strStatus" label="状态"> </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="formData.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="formData.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { rechargeRecordApi } from "../../api/Finance.js";
import { sendFile } from "../../api/sendFile";
export default {
  name: "rechargeRecord",
  data() {
    return {
      options: [],
      formData: {
        phone: "",
        trueName: "",
        fatherId: 0,
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      editForm: {
        status: "",
        remark: "",
      },
      tableData: [],
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
    };
  },
  created() {
    this.getTableList();
  },
  mounted() {},
  methods: {
    async getTableList() {
      const { data } = await rechargeRecordApi(this.formData);
      this.tableData = data.list;
      this.formData.total = data.pagination.total;
      this.formData.pageSize = data.pagination.pageSize;
      this.formData.currentPage = data.pagination.current;
    },
    handleSizeChange(num) {
      console.log(num);
      this.formData.pageSize = num;
      this.getTableList();
    },
    handleCurrentChange(num) {
      console.log("currentPage", num);
      this.formData.currentPage = num;
      this.getTableList();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .add {
    margin-top: 20px;
  }
  .el-table {
    margin-top: 50px;
  }
  .search_box {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 20px;
    }
    span {
      display: inline-block;
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid #f1f1f1;
  }
  /deep/.el-dialog__footer {
    border-top: 1px solid #f1f1f1;
  }
  .btn_box {
    margin-top: 24px;
  }
  .modify_btn {
    padding-left: 10px;
    padding-right: 10px;
  }
  /deep/.popconfirm {
    margin-right: 10px;
  }
}
</style>